import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Body3 from '../../../tags/body3'
import ServiceTitle from '../../../tags/service.title'

const MedicalHistory = () => (
  <article className="w-full lg:max-w-7xl mx-auto px-4 md:px-page-x-sm lg:px-page-x-s py-page-y flex flex-col md:flex-row justify-between">
    <Typograph />
    <Image />    
  </article>
)
const Typograph = () => {
  const {t} = useTranslation();
  return (
    <section className="flex flex-col">
      <ServiceTitle>{t("medical.history.title")}</ServiceTitle>
      <Body3 className="mt-8 whitespace-pre-wrap">{t("medical.history.desc")}</Body3>
    </section>
  )
}

const Image = () => (
  <section className="mt-8 md:mt-0 flex justify-center md:justify-end">
    <StaticImage
      src="../../../../images/service/medical_history.png"
      alt="medical history"
      quality={100}
      className="sm:w-full md:w-[90%] lg:w-full"
    />
  </section>
)

export default MedicalHistory;