import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import MedicalLogo from "../../../icons/medical.logo"

const MedicalComingSoon = () => (
  <article className="w-full bg-subBgc2">
    <div className="w-full lg:max-w-7xl mx-auto px-4 md:px-page-x-sm lg:px-page-x-s py-page-y flex flex-col items-center">
      <MedicalLogo className="w-[287px]" />
      <div
        className="whitespace-pre-wrap mt-[62px] text-[62px] leading-[62px] text-white font-bold text-center font-metropolis"
        style={{
          textShadow:
            "-1px -1px 0 #4E79E7, 1px -1px 0 #4E79E7, -1px 1px 0 #4E79E7, 1px 1px 0 #4E79E7",
        }}
      >
        {`COMING\nSOON`}
      </div>
      <Images />
    </div>
  </article>
)
const Images = () => (
  <section className="relative w-full flex justify-center">
    <StaticImage
      src="../../../../images/service/medical_m01_laid.png"
      quality={100}
      alt="medical m01"
    />
  </section>
)
export default MedicalComingSoon
