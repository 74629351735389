import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import MedicalLogo from "../../../icons/medical.logo"

const MedicalMainArticle = () => (
  <article className="w-full bg-medical">
    <div className="w-full lg:max-w-7xl mx-auto px-4 md:px-page-x-sm lg:px-page-x-s py-0 md:py-page-y flex justify-between items-center">
      <div className="flex-1 flex flex-col mb-40 md:mb-0">
        <MedicalLogo className="w-[171px] md:w-[287px]" />
        <div
          className="whitespace-pre-line mt-8 md:mt-[62px] text-[32px] md:text-[60px] lg:text-[94px] leading-[32px] md:leading-[60px] lg:leading-[94px] text-white font-bold font-metropolis"
          style={{
            textShadow:
              "-1px -1px 0 #4E79E7, 1px -1px 0 #4E79E7, -1px 1px 0 #4E79E7, 1px 1px 0 #4E79E7",
          }}
        >
          {`COMING 
          SOON`}
        </div>
      </div>
      <div className="flex-1 flex justify-end min-h-[600px]">
        <StaticImage
          src="../../../../images/service/medical_m01.png"
          alt="medical Bulo M"
          quality={100}
        />
      </div>
    </div>
  </article>
)

export default MedicalMainArticle
