import { StaticImage } from 'gatsby-plugin-image';
import React from 'react'
import { useTranslation } from 'react-i18next';
import Body3 from '../../../tags/body3';
import ServiceTitle from '../../../tags/service.title';

const MedicalMeasure = () => (
  <article className="w-full lg:max-w-7xl mx-auto px-4 md:px-page-x-sm lg:px-page-x-s py-page-y flex flex-col-reverse md:flex-row md:justify-between">
    <Images />
    <Typograph />
  </article>
)
const Typograph = () => {
  const {t} = useTranslation();
  return (
    <section className="flex flex-col items-end">
      <ServiceTitle className="text-right">{t("medical.measure.title")}</ServiceTitle>
      <Body3 className="mt-8 text-right whitespace-pre-wrap">{t("medical.measure.desc")}</Body3>
    </section>
  )
}
const Images = () => (
  <section className="mt-9 md:mt-0 flex justify-start">
    <StaticImage
      src="../../../../images/service/medical_measure.png"
      alt="medical measure"
      quality={100}
    />
  </section>  
)
export default MedicalMeasure;