import { StaticImage } from 'gatsby-plugin-image';
import React from 'react'
import { useTranslation } from 'react-i18next';
import Body3 from '../../../tags/body3';
import ServiceTitle from '../../../tags/service.title';

const MedicalProfiles = () => (
  <article className="w-full lg:max-w-7xl mx-auto px-4 md:px-page-x-sm lg:px-page-x-s py-page-y flex flex-col md:flex-row justify-between">
    <Typograph />
    <Images />
  </article>
)
const Typograph = () => {
  const {t} = useTranslation();
  return (
    <section className="flex flex-col">
      <ServiceTitle>{t("medical.profile.title")}</ServiceTitle>
      <Body3 className="mt-8 whitespace-pre-wrap">{t("medical.profile.desc")}</Body3>
    </section>
  )
}
const Images = () => (
  <section className="flex mt-9 lg:mt-0 md:w-[308px] lg:w-auto justify-center lg:justify-end">
    <StaticImage
      src="../../../../images/service/medical_profile.png"
      alt="Medical Profile"
      quality={100}
    />
  </section>
)
export default MedicalProfiles;