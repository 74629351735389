import React from 'react'
import { useTranslation } from 'react-i18next';
import MedicalLogo from '../../../icons/medical.logo';
import ServiceWrapLine from '../../../icons/service.wrap.line';
import ServiceIntroLine from '../../../images/service.intro'
import Body2 from '../../../tags/body2';
import Body3 from '../../../tags/body3';

const MedicalIntro = () => {
  const {t} = useTranslation();
  return (
    <article className="relative w-full lg:max-w-7xl mx-auto px-4 md:px-page-x-sm lg:px-page-x-s h-[332px]">
      <IntroLine />
      <div className="w-full h-full pb-12 px-4 md:px-page-x-sm lg:px-page-x-s flex flex-col justify-center items-center">
        <IntroLogo />
        <Body2 className="mt-[34px]">{t("medical.intro.title")}</Body2>
        <Body3 className="mt-3 text-center">{t("medical.intro.desc")}</Body3>
      </div>
    </article>
  )
}
const IntroLine = () => (
  <div className="absolute px-4 md:px-page-x-sm lg:px-page-x-s left-0 top-0 w-full h-full">
    <ServiceWrapLine />
  </div>
)

const IntroLogo = () => (
  <div className="flex flex-col items-end space-y-2">
    <MedicalLogo className="w-[232px]" />
  </div>
)
export default MedicalIntro;