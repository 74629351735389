import React from 'react'
import MedicalHistory from '../../components/pages/service/medical/history.medical'
import MedicalIntro from '../../components/pages/service/medical/intro.medical'
import MedicalMainArticle from '../../components/pages/service/medical/main.article'
import MedicalMeasure from '../../components/pages/service/medical/measure.medical'
import MedicalProfiles from '../../components/pages/service/medical/profile.medical'
import MedicalComingSoon from '../../components/pages/service/medical/soon.medical'
import Main from '../../layout/main'

const ServiceIntro = (prop) => (
  <Main title="메디컬 서비스" {...prop}>
    <MedicalMainArticle />
    <MedicalIntro />
    <MedicalProfiles />
    <MedicalMeasure />
    <MedicalHistory />
    <MedicalComingSoon />
  </Main>
)
export default ServiceIntro